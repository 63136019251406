import React, { useEffect } from "react";
import comingSoon from "../assets/coming-soon1.jpg";
import HomeMain from "./landing_page/HomeMain";

const LandingHome = () => {
  useEffect(() => {
    document.title = "Confess-Home";
  }, []);
  return (
    <div>
      <HomeMain />
      {/* <div className="landing_page d-flex align-items-center justify-content-center vh-100">
        <img src={comingSoon} />
      </div> */}
    </div>
  );
};

export default LandingHome;
