import React, { useRef } from "react";
import "./home.css";
import Home from "./Home";
import PickTheSun from "./PickTheSun";
import EasyReply from "./EasyReply";
import BlockSender from "./BlockSender";
import SenderInfo from "./SenderInfo";
import PreservingSafety from "./PreservingSafety";
import Comp_FAQ from "./Comp_FAQ";
import WebFooter from "./WebFooter";
const HomeMain = () => {
  const secondComponentRef = useRef(null);

  const handleButtonClick = () => {
    if (secondComponentRef.current) {
      secondComponentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="gradient-background ">
      <Home onClick={handleButtonClick} />
      <PickTheSun ref={secondComponentRef} />
      <EasyReply />
      <BlockSender />
      <SenderInfo />
      <PreservingSafety />
      <Comp_FAQ />
      <WebFooter />
    </div>
  );
};

export default HomeMain;
