import React, { useState, useEffect, useRef } from "react";
// import mobile from "./images/comp3_mobile.png";
import { Container } from "react-bootstrap";
import mobile from "./images/mobile6.png";

const ComponentSix = () => {
  const [isToggled, setIsToggled] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsToggled(true);
        } else {
          setIsToggled(false);
        }
      },
      { threshold: 0.1 } // Adjust this threshold as needed
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);
  const initialStyle = {
    marginTop: "10%",
    transform: "translateY(10px) scale(0.7) rotate(0deg) translateZ(0px)",
    transition: "transform 0.5s ease",
    objectFit: "contain",
  };

  const toggledStyle = {
    marginTop: "10%",
    transform: "translateY(0px) scale(1) rotate(0deg) translateZ(0px)",
    transition: "transform 0.5s ease",
    objectFit: "contain",
  };
  return (
    <div className="">
      <div className="comp6_custom">
        <Container>
          <div className="row flex-column-reverse flex-lg-row d-flex justify-content-between  comp_second_custom">
            <div className=" col-0 col-lg-1"></div>
            <div className="pe-0 pe-lg-5 col-12 col-lg-5 text-center text-lg-end movile_main_image_fifth_six comp_3_mobile_custom">
              <img
                src={mobile}
                alt="mobile"
                style={isToggled ? toggledStyle : initialStyle}
                ref={imageRef}
              />
            </div>
            <div className="ps-0 ps-lg-5 col-12 col-lg-5 d-flex flex-column justify-content-center align-items-center align-items-lg-start ">
              <div className="comp_2_custom">No more waste</div>
              <div className="responsive_heading_field display-4 fw-bold text-center text-sm-start pe-0 pe-lg-5 ">
                Preserving Safety{" "}
              </div>
              <div className="comp_2_custom_text fs-normal text-center text-lg-start mx-4 mx-sm-0 pe-0 pe-lg-5">
                We made CONFESS as a place where you can be your real, honest,
                authentic self. Silly, somber, sassy, and everything in between.
                But what’s not OK — what’s never OK — is attacking,
                intimidating, or otherwise being unkind to others. We’re talking
                about bullying, and it can take lots of different forms.
                Stopping it is a team effort, so here’s a rundown of how
                bullying is handled on CONFESS: how we keep users safe and what
                to do if you see it happening.
              </div>
            </div>
            <div className="col-0 col-lg-1"></div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ComponentSix;
