import React from "react";
import logo from "../assets/logo.png";
import Footer from "./Footer";
import postImage from "../assets/postImageUpdated.png";
import { useNavigate } from "react-router-dom";
const PostError = () => {
  const navigate = useNavigate();
  const navigateFn = () => {
    navigate("/");
  };
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        minHeight: "100vh",
      }}
    >
      <div>
        <span className="d-flex justify-content-center log-image mb-4 mt-3">
          <img
            className=" cursor-pointer"
            src={logo}
            onClick={navigateFn}
            style={{ cursor: "pointer" }}
          />
        </span>
      </div>
      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex  justify-content-center img_handler mb-3">
          <img
            src={postImage}
            className="error_image"
            // style={{ minHeight: "20vh" }}
          />
        </div>
        {/* </div>

            <div className="col-0 col-lg-4  "></div>
          </siv>
        </div> */}

        <div className="d-flex flex-column justify-content-center ">
          <div className="d-flex  justify-content-center postError_text">
            Oops!{" "}
          </div>
          <div className="d-flex  justify-content-center postError_text_sub mb-4">
            Post not found
          </div>
          <div className="d-flex  justify-content-center postError_text_sub ">
            <button className=" error_button" onClick={navigateFn}>
              Go To Homepage
            </button>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default PostError;
