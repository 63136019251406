import React from "react";
// import google from "./images/Google Play.png";
import google from "./images/Website/Web_View/TryNow/StoreButtons/Compressed/Google Play.png";
import apple from "./images/Website/Web_View/TryNow/StoreButtons/Compressed/App Store.png";
import { Link } from "react-router-dom";

const WebFooter = () => {
  return (
    <div>
      <div className="web_footer">
        <div
          className="web_footer_seoond d-flex justify-content-center align-items-center flex-column"
          style={{ height: "inherit" }}
        >
          <div className="display-4 fw-bold mb-2">Try Now</div>
          <div className="reponsive_margin mx-4 mx-sm-0 comp_2_custom_text">
            Et pulvinar nec interdum integer id urna molestie porta nullam.
          </div>
          <div className="row store_icons d-flex justify-content-center">
            {/* <button className="col-12 col-sm-6 border-0  specfic_btn_one bg-transparent responsive_size">
              <a>
                <img src={google} alt="google" />
              </a>
            </button> */}
            <button className="col-12  mt-3 mt-sm-0 border-0 bg-transparent responsive_size">
              <a
                href="https://apps.apple.com/us/app/confess-anonymous-q-a/id6502970808"
                target="_blank"
              >
                <img src={apple} alt="apple" />
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className="footer_main">
        <div className=" text-white d-flex flex-column  justify-content-center align-items-center py-3">
          <div className="mb-1 terms_div d-flex flex-column flex-sm-row text-center">
            {" "}
            <Link
              className="web_links my-sm-0 my-2"
              to="/privacy-policy"
              target="_blank"
            >
              Privacy Policy{" "}
            </Link>{" "}
            <Link
              to="/terms"
              target="_blank"
              className="ms-0 ms-sm-4 my-sm-0 my-2 web_links"
            >
              {" "}
              Terms & Conditions{" "}
            </Link>
            <Link
              to="mailto:getconfessapp@gmail.com"
              target="_blank"
              className="ms-0 ms-sm-4 my-sm-0 my-2 web_links"
            >
              {" "}
              Contact Us{" "}
            </Link>
          </div>
          <div
            className="d-flex flex-column flex-sm-row"
            style={{ fontSize: "16px" }}
          >
            <div className="text-center  mb-sm-1 mb-3"> © 2024 Confess</div>
          </div>
          <div className="font_set">
            Designed & Developed By:&nbsp;
            <Link
              className=" mb-sm-0 mb-3 web_links_comp"
              to="https://inoidsolutions.com/"
              target="_blank"
            >
              iNoid Solutions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebFooter;
