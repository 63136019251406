import "./App.css";
import Home from "./Components/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Navigate,
} from "react-router-dom";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsConditions from "./Components/TermsConditions";
import Footer from "./Components/Footer";
import NotFound from "./Components/NotFound";
import LandingHome from "./Components/LandingHome";
import PostError from "./Components/PostError";

function App() {
  const { post_id } = useParams();
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          {/* <Route element={<Home />} path="/home" /> */}
          {post_id ? (
            <Route element={<Home />} path="/:post_id" />
          ) : (
            <Route element={<LandingHome />} path="/" />
          )}
          <Route element={<Home />} path="/:post_id" />
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<TermsConditions />} path="/terms" />
          {/* <Route element={<PostError />} path="/posterror" /> */}
          <Route element={<NotFound />} path="*" />
        </Routes>
        {/* {user_id ? */}

        {/* // : ""} */}
      </BrowserRouter>
    </div>
  );
}

export default App;
