import React, { useEffect, useState } from "react";
import logo from "./images/logo.png";
// import mobile_img from "./images/Website/Web_View/Hero_Banner/Graphic/Compressed/Hero_banner_graphic.png";
import mobile_img from "./images/mini_images/Hero_banner_graphic.png";
import google from "./images/Website/Web_View/TryNow/StoreButtons/Compressed/Google Play.png";
import apple from "./images/Website/Web_View/TryNow/StoreButtons/Compressed/App Store.png";
import arrow from "./images/arrow.png";
import { Container } from "react-bootstrap";

const ComponentsOne = ({ onClick }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const setViewportHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    // Initial execution
    setViewportHeight();

    // Update the value on resize and orientation change
    window.addEventListener("resize", setViewportHeight);
    window.addEventListener("orientationchange", setViewportHeight);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("resize", setViewportHeight);
      window.removeEventListener("orientationchange", setViewportHeight);
    };
  }, []);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isWideScreen]);
  // const toggleArrowVisibility = () => {
  //   if (window.scrollY > 150) {
  //     setIsVisible(false);
  //   } else {
  //     setIsVisible(true);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", toggleArrowVisibility);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", toggleArrowVisibility);
  //   };
  // }, []);
  // {`comp1_main main_page_responsive_view comp2_custom position-relative ${isWideScreen ? 'container-1600' : ''}`}
  return (
    <div
      className={`comp1_main main_page_responsive_view comp2_custom position-relative ${
        isWideScreen ? "container-1600" : ""
      }`}
    >
      <Container>
        <div className=" text-center text-sm-start ">
          <img className="mt-3" src={logo} alt="Confress" />
        </div>
        <div className="row flex-column-reverse flex-md-row comp1_custom_style ">
          <div className=" col-12 col-md-7 col-xl-7 d-flex flex-column justify-content-center responsive_margin">
            <div className="home_comp1_text  text-sm-start   ">
              Get <span className="text_blue">Anonymous</span> Messages
            </div>
            <div>
              <p className="sub_title_text  text-sm-start">
                Lorem ipsum dolor sit amet consectetur. Etiam{" "}
                <br className="d-none d-lg-block" /> nunc sem velit interdum
                tristique tellus.
              </p>
            </div>
            {/* store_icons */}
            <div className=" store_icons  justify-content-start justify-content-sm-start">
              {/* <button className="border-0 specfic_btn_one  bg-transparent">
                <img src={google} alt="google" />
              </button> */}
              <button className="specific_btn border-0 bg-transparent ">
                <a
                  href="https://apps.apple.com/us/app/confess-anonymous-q-a/id6502970808"
                  target="_blank"
                >
                  <img src={apple} alt="apple" />
                </a>
              </button>
            </div>
          </div>
          <div className="col-12 col-md-5 col-xl-5 movile_main_image text-center text-sm-start ">
            <img
              src={mobile_img}
              alt="mobiel_image"
              className="custom_width_Set"
            />
          </div>
          {/* <div className=""> */}
          <span className="arrow-icon arrow_position position-absolute">
            <img src={arrow} alt="arrow" onClick={onClick} />
          </span>
        </div>
      </Container>
    </div>
  );
};

export default ComponentsOne;
