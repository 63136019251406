import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAKVgvp0fqKILUbDUIj1cROgBcdQv2svEs",
  authDomain: "confess-a0006.firebaseapp.com",
  databaseURL: "https://confess-a0006-default-rtdb.firebaseio.com",
  projectId: "confess-a0006",
  storageBucket: "confess-a0006.appspot.com",
  messagingSenderId: "941906296120",
  appId: "1:941906296120:web:ed427e6b50493704af15cd",
  measurementId: "G-N1GSVJRBTY",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getFirestore(app);

export { app, auth, database };
