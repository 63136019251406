import React, { useState, useEffect, useRef } from "react";
// import mobile from "./images/comp3_mobile.png";
import { Container } from "react-bootstrap";
// import mobile from "./images/comp4.png";
import mobile from "./images/Website/Web_View/Graphics/ReportUser/Compressed/Report_User.png";

const ComponentFouth = () => {
  const [isToggled, setIsToggled] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsToggled(true);
        } else {
          setIsToggled(false);
        }
      },
      { threshold: 0.1 } // Adjust this threshold as needed
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);
  const initialStyle = {
    marginTop: "10%",
    transform: "translateY(10px) scale(0.7) rotate(0deg) translateZ(0px)",
    transition: "transform 0.5s ease",
    objectFit: "contain",
  };

  const toggledStyle = {
    marginTop: "10%",
    transform: "translateY(0px) scale(1) rotate(0deg) translateZ(0px)",
    transition: "transform 0.5s ease",
    objectFit: "contain",
  };
  return (
    <div className="">
      <div className="comp4_custom">
        <Container>
          <div className="row flex-column-reverse flex-lg-row d-flex justify-content-between  comp_second_custom">
            <div className="col-0 col-lg-1"></div>
            <div className="pe-0 pe-lg-5 col-12 col-lg-5 text-center text-lg-end">
              <img
                className="new_mobile_image custom_lg_style"
                src={mobile}
                alt="mobile"
                style={isToggled ? toggledStyle : initialStyle}
                ref={imageRef}
              />
            </div>
            <div className="ps-0 ps-lg-5 col-12 col-lg-5 d-flex flex-column justify-content-center align-items-center align-items-lg-start ">
              <div className="comp_2_custom">No more waste</div>
              <div className="display-4 fw-bold responsive_heading_field text-center text-sm-start px-5 px-sm-0">
                Block The Sender
              </div>
              <div className="comp_2_custom_text text-lg-start text-center mx-5 mx-sm-0">
                Et pulvinar nec interdum integer id urna molestie porta nullam.
                A donec ornare sed turpis pulvinar purus m pulvinar purus m
              </div>
            </div>
            <div className="col-0 col-lg-1"></div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ComponentFouth;
