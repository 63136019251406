import React from "react";

const NotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <h1>404:Page Not Found</h1>
    </div>
  );
};

export default NotFound;
