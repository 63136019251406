import React from "react";
import mobile from "./images/mobile5.png";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
const Comp_FAQ = () => {
  return (
    <div className="">
      <div className="comp7_custom pb-5 ">
        <Container>
          <div className="comp_second_custom d-flex flex-column justify-content-center align-items-center faq_main">
            <div className="comp_2_custom">No more waste</div>
            <div className="display-4 fw-bold responsive_heading_field">
              FAQ’s
            </div>
            <div className="comp_2_custom_text mx-5 text-center">
              Et pulvinar nec interdum integer id urna molestie porta nullam. A,
              donec ornare sed turpis pulvinar purus maecenas quam a. Erat
              porttitor pharetra sed in
            </div>
          </div>

          <Accordion defaultActiveKey="0" className="mt-3">
            <Accordion.Item eventKey="0" className="mb-3">
              <Accordion.Header>Can I have multiple accounts?</Accordion.Header>
              <Accordion.Body>
                At the moment, we only allow for 1 NGL account per device. If
                you delete your account or remove the app from your phone, you
                will lose your messages. Trying to log into your account on a
                new device will create another account with a new link and
                inbox.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="mb-3">
              <Accordion.Header>How do i see my messages?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="mb-3">
              <Accordion.Header>How do I report abuse??</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="mb-3">
              <Accordion.Header>Can I recover my account?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
    </div>
  );
};

export default Comp_FAQ;
