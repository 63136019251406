import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "Confess|Privacy-Policy";
  }, []);
  return (
    <Container>
      <div>
        <div className="pt-4 pb-4">
          <h1 className=" text-center">Privacy Policy</h1>
          <br />
          <p className="paragraph-5">
            Last Updated: March 24<sup>th</sup>, 2024
            <br />
            <br />
            This Privacy Policy describes the privacy practices of Confess and
            how we handle personal information that we collect through our
            application, website and any other sites or services (collectively,
            the &ldquo;Services&rdquo;).
            <br />
            <br />
            &zwj;
            <strong>
              The basics&nbsp;
              <br />
              &zwj;
            </strong>
            Confess allows users to receive messages through their social media.
            This is how it works:
            <br />
            <br />
            The Confess app permits a user (&ldquo;App User&rdquo;) to generate
            an Confess link and then use the link to solicit messages from
            anyone with access to the link (the person sending a message is a
            &ldquo;Message Sender&rdquo;).
            <strong>
              &nbsp;
              <br />
              <br />
              &zwj;
            </strong>
            When a Message Sender clicks on the Confess link, they will be taken
            to a URL where they can send a message directly to the App User. The
            App User also has the choice to post the messages and App
            User&rsquo;s response to the App User&rsquo;s social media
            accounts.&nbsp;
            <br />
            <br />
            Confess does not store the name or social media handles of the
            Message Sender, and cannot reveal this information to the App User
            or to others. Confess may reveal properties of the Message Sender to
            the App User, based on the Message Sender&rsquo;s IP address or
            browser user agent. Additionally, Confess may share this information
            with law enforcement for compliance, safety, and integrity
            issues.&nbsp;
            {/* <br />
            <br />
            To protect the safety of our users, we employ AI content moderation
            services like&nbsp;
            <a href="https://hivemoderation.com/">Hive Moderation</a>. These
            services utilize deep learning and sophisticated algorithms to
            screen messages and filter out harmful language and bullying. */}
            <br />
            <br />
            &zwj;
            <strong>
              Personal information we collect
              <br />
              &zwj;
            </strong>
            We collect information about you from a variety of sources, and the
            type of information we collect depends on how you interact with our
            Services &ndash; such as whether you visit our website, download the
            Confess app, or send messages to App Users through the Services.
            <br />
            &zwj;
            <br />
            &zwj;
            <strong>
              Personal information you provide to us:
              <br />
              <br />
              Account information,&nbsp;
            </strong>
            that App Users provide when they download the Confess app and sign
            up to use the Services. App Users may provide their social media
            handle so that we can link the Services to their accounts. They may
            also upload a profile picture for their Confess account.
            <br />
            <br />
            &zwj;<strong>Messages&nbsp;</strong>that Message Senders submit to
            App Users through the Services.&nbsp;
            <br />
            <br />
            &zwj;<strong>Feedback or correspondence,</strong>&nbsp;such as
            information you provide when you contact us with questions,
            feedback, product reviews, or otherwise correspond with us online.
            <br />
            <br />
            &zwj;<strong>Usage information,</strong>&nbsp;such as information
            about how you use the Services and interact with us, including
            information associated with any content you upload to the Services
            or otherwise submit to us, and information you provide when you use
            any interactive features of the Services.&nbsp;
            <br />
            <br />
            &zwj;<strong>Other information</strong>&nbsp;that we may collect
            which is not specifically listed here, but which we will use in
            accordance with this Privacy Policy or as otherwise disclosed at the
            time of collection.&nbsp;
            <br />
            <br />
            &zwj;
            <strong>Information we obtain from other sources.&nbsp;</strong>We
            may obtain your personal information from other third parties, such
            as marketing partners, publicly-available sources, lead generators
            and data providers.
            <strong>
              &nbsp;
              <br />
              <br />
              Automatic data collection.
            </strong>
            &nbsp;We and our service providers may automatically log information
            about you, your computer or mobile device, and your interaction over
            time with our Services, our communications and other online
            services, such as:
            <br />
            &zwj;
            <br />
            <strong>Device data,&nbsp;</strong>such as your computer&rsquo;s or
            mobile device&rsquo;s operating system type and version,
            manufacturer and model, browser type, screen resolution, RAM and
            disk size, CPU usage, device type (e.g., phone, tablet), IP address,
            unique identifiers (including identifiers used for advertising
            purposes), language settings, mobile device carrier, radio/network
            information (e.g., WiFi, LTE, 4G), and general location information
            such as city, state or geographic area.
            <br />
            <br />
            &zwj;<strong>Online activity data</strong>, such as&nbsp; pages or
            screens you viewed, how long you spent on a page or screen, browsing
            history, navigation paths between pages or screens, information
            about your activity on a page or screen, access times, and duration
            of access, and whether you have opened our marketing emails or
            clicked links within them.
            <br />
            <br />
            &zwj;
            <strong>
              We use the following tools for automatic data collection:
              <br />
              <br />
              Cookies
            </strong>
            , which are text files that websites store on a visitor&lsquo;s
            device to uniquely identify the visitor&rsquo;s browser or to store
            information or settings in the browser for the purpose of helping
            you navigate between pages efficiently, remembering your
            preferences, enabling functionality, helping us understand user
            activity and patterns, and facilitating online advertising.
            <br />
            <br />
            &zwj;<strong>Local storage technologies</strong>, like HTML5, that
            provide cookie-equivalent functionality but can store larger amounts
            of data, including on your device outside of your browser in
            connection with specific applications.
            <br />
            <br />
            &zwj;<strong>Web beacons</strong>, also known as pixel tags or clear
            GIFs, which are used to demonstrate that a webpage or email was
            accessed or opened, or that certain content was viewed or clicked.
            <br />
            <br />
            &zwj;
            <strong>
              How we use your personal information
              <br />
              &zwj;
            </strong>
            We use Personal information for one or more of the following
            purposes:
            <br />
            &zwj;
            <br />
            <strong>Providing and supporting the Services.</strong>&nbsp;We use
            your personal information to operate, maintain, and provide you with
            the Services. In particular we will use your personal information to
            perform our contractual obligation under our terms of use towards
            you, such as to:Allow you to create an account and use the
            Services.Respond to your requests, provide customer support, contact
            you in case of any issue with your account, and send you
            announcements, updates, security alerts, and support and
            administrative messages.
            <br />
            <br />
            &zwj;
            <strong>
              Improve, monitor, personalize, and protect our Services and
              communications.&nbsp;
            </strong>
            It is in our legitimate business interests to improve and keep our
            Services safe for our users, which includes:Troubleshooting, testing
            and research and to keep the Services secure.Investigating and
            protecting against fraudulent, harmful, unauthorized or illegal
            activity.Providing customized Services and communications that may
            be relevant or of interest to you.
            <br />
            <br />
            &zwj;<strong>Marketing and advertising,&nbsp;</strong>including for:
            <br />
            <br />
            &zwj;<strong>Direct marketing.</strong>&nbsp;We may send you direct
            marketing communications as permitted by law, including, but not
            limited to, sending newsletters, and notifying you of special
            promotions, offers and events via email and other means.&nbsp;
            <br />
            <br />
            &zwj;<strong>Interest-based advertising.</strong>&nbsp;We engage our
            advertising partners, including third party advertising companies
            and social media companies, to advertise our Services.&nbsp;Where
            applicable, if you choose to subscribe to our marketing and
            advertising, we process your personal information based on your
            consent, which you may withdraw at any time.
            <br />
            <br />
            &zwj;
            <strong>
              To enforce our agreements, to comply with legal obligations and to
              defend us against legal claims or disputes
            </strong>
            . We may use your personal information in our legitimate business
            interests to enforce and comply with our terms and policies, to
            ensure the integrity of our Services and to defend ourselves against
            legal claims or disputes. Some processing may also be necessary to
            comply with a legal obligation, for example to keep records of
            transactions, or as requested by any judicial process or
            governmental agency.
            <br />
            <br />
            &zwj;
            <strong>
              How we share your personal information
              <br />
              <br />
              Service providers.
            </strong>
            &nbsp;We may share your personal information with third party
            companies and individuals that provide services on our behalf or
            help us operate our Services (such as customer support, content
            moderation, hosting, analytics, email delivery, marketing, identity
            verification, fraud detection, payment processing, and database
            management).
            <br />
            <br />
            &zwj;<strong>Advertising partners.</strong>&nbsp;We may share your
            personal information with third party advertising companies,
            including for the&nbsp;interest-based advertising&nbsp;purposes
            described above.
            <br />
            <br />
            &zwj;
            <strong>For compliance, fraud prevention and safety.&nbsp;</strong>
            We may share your personal information for the compliance, fraud
            prevention and safety purposes described above.&nbsp;
            <br />
            <br />
            &zwj;<strong>Business transfers.</strong>&nbsp;We may sell, transfer
            or otherwise share some or all of our business or assets, including
            your personal information, in connection with a business transaction
            (or potential business transaction) such as a corporate divestiture,
            merger, consolidation, acquisition, reorganization or sale of
            assets, or in the event of bankruptcy or dissolution. In such a
            case, we will make reasonable efforts to require the recipient to
            honor this Privacy Policy.
            <br />
            <br />
            &zwj;
            <strong>
              How you share your personal information
              <br />
              <br />
              Other social media users:
            </strong>
            &nbsp;App Users may choose to post their responses to messages they
            receive through the Services to their social media accounts. Your
            social media settings will determine which other users will be able
            to view these responses.
            <br />
            <br />
            &zwj;
            <strong>
              Your rights and choices
              <br />
              <br />
              Personal information requests.&nbsp;
            </strong>
            We also offer you choices that affect how we handle the personal
            information that we control. You may request the following in
            relation to your personal information:&nbsp;
            <br />
            <br />
            &zwj;<strong>Information</strong>&nbsp;about how we have collected
            and used your personal information. We have made this information
            available to you without having to request it by including it in
            this Privacy Policy.
            <br />
            <br />
            &zwj;<strong>Access</strong>&nbsp;to a copy of the personal
            information that we have collected about you. Where applicable, we
            will provide the information in a portable, machine-readable,
            readily usable format.
            <br />
            <br />
            &zwj;<strong>Correction&nbsp;</strong>of personal information that
            is inaccurate or out of date.
            <br />
            <br />
            &zwj;<strong>Deletion</strong>&nbsp;of personal information that we
            no longer need to provide the Services or for other lawful purposes.
            <br />
            <br />
            &zwj;<strong>Additional rights</strong>, such as to&nbsp;
            <strong>object</strong>&nbsp;to and request that we&nbsp;
            <strong>restrict</strong>&nbsp;our use of your personal information,
            and where applicable, you may&nbsp;<strong>withdraw</strong>
            &nbsp;your consent.
            <br />
            <br />
            To make a request, please email us or write to us as provided in the
            &ldquo;Contact us&rdquo; section below. We may ask for specific
            information from you to help us confirm your identity. California
            residents can empower an &ldquo;authorized agent&rdquo; to submit
            requests on their behalf. We will require authorized agents to
            confirm their identity and authority, in accordance with applicable
            laws. You are entitled to exercise the rights described above free
            from discrimination.
            <br />
            <br />
            &zwj;<strong>Limits on your choices.&nbsp;</strong>In some
            instances, your choices may be limited, such as where fulfilling
            your request would impair the rights of others, our ability to
            provide a service you have requested, or our ability to comply with
            our legal obligations and enforce our legal rights. If you are not
            satisfied with how we address your request, you may submit a
            complaint by contacting us as provided in the &ldquo;Contact
            us&rdquo; section below.&nbsp;
            <br />
            <br />
            {/* &zwj;<strong>Do Not Track.&nbsp;</strong>Some Internet browsers may
            be configured to send &ldquo;Do Not Track&rdquo; signals to the
            online services that you visit.&nbsp;&nbsp;We currently do not
            respond to &ldquo;Do Not Track&rdquo; or similar signals.&nbsp;To
            find out more about &ldquo;Do Not Track,&rdquo; please visit&nbsp;
            <a href="http://www.allaboutdnt.com/">http://www.allaboutdnt.com</a>
            .<br />
            <br /> */}
            {/* &zwj; */}
            <strong>
              Children&rsquo;s privacy
              <br />
              &zwj;
            </strong>
            Our Services are not intended for use by children under 13 years of
            age. If we learn that we have collected personal information through
            the Services from a child under 13 without the consent of the
            child&rsquo;s parent or guardian as required by law, we will delete
            it.&nbsp; If you are a parent or guardian and you are aware that
            your Child has provided us with personal information, please contact
            us as set out in the &ldquo;Contact us&rdquo; section below. In
            certain cases, this age may be higher than 13 due to local
            regulatory requirements. &nbsp;
            <br />
            <br />
            &zwj;
            <strong>
              International data transfers
              <br />
              &zwj;
            </strong>
            Since we are based in the United States, you will provide your data
            directly to us in the United States. We may transfer your personal
            information to our affiliates and service providers in the United
            States and other jurisdictions. Please note that such jurisdictions
            may not provide the same protections as the data protection laws in
            your home country.In these instances we will ensure that relevant
            safeguards are in place to afford adequate protection for your
            personal information and we will comply with applicable data
            protection laws, in particular by relying on an EU Commission or UK
            government adequacy decision or on contractual protections for the
            transfer of your personal information. For more information about
            how we transfer personal information internationally, please contact
            us as set out in the &ldquo;Contact us&rdquo; section below.
            <br />
            <br />
            &zwj;
            <strong>
              Retention of personal information
              <br />
              &zwj;
            </strong>
            We retain your personal information only for as long as is necessary
            to fulfil the purposes for which it was collected and processed, in
            accordance with our retention policies, and in accordance with
            applicable laws or until you withdraw your consent (where
            applicable).&nbsp;
            <br />
            To determine the appropriate retention period for your personal
            information, we consider the amount, nature, and sensitivity of the
            personal information, the potential risk of harm from unauthorized
            use or disclosure of your personal information, the purposes for
            which we use your personal information and whether we can achieve
            those purposes through other means, and the applicable legal
            requirements.&nbsp;
            <br />
            <strong>
              <br />
              Security practices
              <br />
              &zwj;
            </strong>
            We use reasonable organizational, technical and administrative
            measures designed to protect against unauthorized access, misuse,
            loss, disclosure, alteration and destruction of personal
            information. Unfortunately, data transmission over the Internet
            cannot be guaranteed as completely secure. Therefore, while we
            strive to protect your personal information, we cannot guarantee the
            security of personal information.&nbsp;
            <br />
            <strong>
              <br />
              Other sites, mobile applications and services&nbsp;
              <br />
              &zwj;
            </strong>
            Our Services may contain links to other websites, mobile
            applications, and other online services operated by third parties.
            These links are not an endorsement of, or representation that we are
            affiliated with, any third party. In addition, our content may be
            included on web pages or in mobile applications or online services
            that are not associated with us.&nbsp;We do not control third-party
            websites, mobile applications or online services, and we are not
            responsible for their actions. Other websites and services follow
            different rules regarding the collection, use and sharing of your
            personal information. We encourage you to read the privacy policies
            of the other websites and mobile applications and online services
            you use.&nbsp;
            <br />
            <br />
            &zwj;
            <strong>
              Changes to this Privacy Policy
              <br />
              &zwj;
            </strong>
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by updating the date of this Privacy Policy and posting it on our
            Services. We may also provide notification of changes in another way
            that we believe is reasonably likely to reach you, such as via
            e-mail (if you have an account where we have your contact
            information) or another manner through our Services.
            <br />
            <br />
            &zwj;
            <strong>
              Contact us
              <br />
              &zwj;
            </strong>
            Confess is the entity responsible for the processing of your
            personal information, and is the data controller in respect of such
            processing. If you have any questions or comments about this Privacy
            Policy, our privacy practices, or if you would like to exercise your
            rights with respect to your personal information, please contact us
            by email at&nbsp;
            <a href="mailto:getconfessapp@gmail.com">getconfessapp@gmail.com</a>
            {/* &nbsp;or write to us at 253 N. La Peer Dr., Beverly Hills, CA
            90211.&nbsp; */}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
