import React, { Fragment, useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { database } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Footer from "./Footer";
import Spinner from "react-bootstrap/Spinner";
import sent from "../assets/sent.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/logo.png";
import Bowser from "bowser";
import google from "./landing_page/images/Website/Web_View/TryNow/StoreButtons/Compressed/Google Play.png";
import apple from "./landing_page/images/Website/Web_View/TryNow/StoreButtons/Compressed/App Store.png";
import PostError from "./PostError";
import user from "../assets/default_avatar.png";

const Home = () => {
  const [message, setMessage] = useState("");
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const [randomVal, setrandomVal] = useState(250);
  const [cardData, setCardData] = useState([]);
  const [cardbgColor, setCardbgColor] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(true);
  const [toggleComp, SetToggleComp] = useState(false);
  const [userID, setUserID] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [successResp, setSuccessResp] = useState(false);
  const [textAreaDisable, setTextAreaDisable] = useState(false);
  const [changeComp, setChangeComp] = useState(true);
  const handleTextareaFocus = () => {
    setIsTextareaFocused(true);
  };
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Confess";
  }, []);

  const handleTextareaBlur = () => {
    setIsTextareaFocused(false);
  };
  const updateDataFn = (event) => {
    setErrorMsg("");
    setMessage(event.target.value);
  };
  const currRef = useRef();

  setInterval(() => {
    setrandomVal(randomVal + 2);
  }, 2000);

  let { post_id } = useParams();

  useEffect(() => {
    if (post_id != undefined) {
      getUserInformation();
    }
  }, []);
  useEffect(() => {
    if (toggleComp === false) {
      currRef.current?.focus();
    }
  }, [toggleComp]);

  const sendUserData = async (user_unique_id) => {
    const customPromise = new Promise(async (resolve, reject) => {
      try {
        let userIP = await getIP();
        let getUserBrowserInfo = await getBrowserInfo();

        const response = await axios.post(
          `${process.env.REACT_APP_WEBSITE_URL}anonymous-user-data`,
          {
            user_unique_id: user_unique_id,
            IPv4: userIP,
            message: message,
            broswer_name: getUserBrowserInfo.browserName,
            os: getUserBrowserInfo.osName,
          },
          {
            headers: {
              post_id: post_id,
            },
          }
        );

        if (response.data.status) {
          resolve(true);
        }
      } catch (error) {
        resolve(false);
        if (error.response) {
          if (error.response.status === 500) {
            toast.error(error.response.data.message);
            setShowSpinner(false);
            setButtonDisable(false);
          } else {
            toast.error(error.message);
          }
        } else {
          toast.error(error.message);
        }
      }
    });

    return customPromise;
  };

  const hadleToggleFn = () => {
    if (message === "") return;
    setMessage("");
    SetToggleComp(false);
    setButtonDisable(false);
  };

  const checkIsUserBlockedOrNot = async () => {
    try {      
       let userIP = await getIP();
      const response = await axios.post(
        `${process.env.REACT_APP_WEBSITE_URL}block-user-admin`,
        { user_ip_address: userIP,
          user_id:userID
         }
      );

      if (response.data.status) {
        // toast.error(response.data.message);
  localStorage.setItem("isUserBlocked", true)
  return true } 
else{
  return false   
}

    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("error....", error);
      return false   
      
    }
  };


  const getUserInformation = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_WEBSITE_URL}user-card`,
        { post_id: post_id }
         // {
        //   headers: { user_id: post_id },
        // }
      );

      if (response.data.status) {
        setUserID(response.data.data.user_id);
        setCardData(response.data.data);
                // let bgGradientColourArray = response.data.data.bg_gradient_colour
        //   .slice(1, -1)
        //   .split(",")
        //   .map((color) => color.trim());

        // setCardbgColor(bgGradientColourArray);
        // console.log(bgGradientColourArray.length);
        setLoading(false);
      } else {
                // toast.error(response.data.message);
        // setTimeout(() => {
        // navigate("/posterror");
        setChangeComp(false);
        setLoading(false);
     // }, 2000);
      }
    } catch (error) {
      toast.error("Internal server error");
      console.log("error....", error);
      setTimeout(() => {
        // navigate("/posterror");
        setChangeComp(false);
        setLoading(false);
      }, 2000);
    }
  };

  const handleSendMessage = async () => {
    let messageNew = message.trim();
    if (messageNew.length <= 0) {
      setMessage("");
      currRef.current?.focus();
      setErrorMsg("Please enter your message");
      return;
    }

    setTextAreaDisable(true);
    setButtonDisable(true);
    setIsTextareaFocused(false);
    // if (message === "") {
    //   setErrorMsg("Please enter your message");
    //   currRef.current?.focus();
    //   return;
    // }
    try {
      setShowSpinner(true);
      let userIP = await getIP();
      let bgGradientColourArray = cardData.bg_gradient_colour
        .slice(1, -1)
        .split(",")
        .map((color) => color.trim());

      let header_color;
      if (cardData.card_type == 2) {
        header_color = "704433";
      } else if (cardData.card_type == 4) {
        header_color = "024D60";
      } else {
        header_color = cardData.header_colour;
      }

    const isUserBlocked =  await checkIsUserBlockedOrNot()
    console.log("isUserBlocked",isUserBlocked);
      if(isUserBlocked){
        SetToggleComp(true);
        setShowSpinner(false);
        setTextAreaDisable(false);
        setButtonDisable(true);
        return
      }
      let docRef = await addDoc(
        collection(
          database,
          "users",
          userID != "" && userID != null && userID != undefined
            ? userID
            : post_id,
          "dms"
        ),
        {
          answer: message.trim(),
          is_open: false,
          question:
            cardData.card_text != ""
              ? cardData.card_text
              : cardData.custom_post_text,
          is_reply: false,
          story_id: post_id,
          timestamp: serverTimestamp(),
          card_type: cardData.card_type,
          ip_address: userIP,
          bg_gradient_colour: bgGradientColourArray,
          header_colour: header_color,
        }
      );

      if (docRef.id != null && docRef.id != undefined && docRef.id != "") {
        let user_resp = await sendUserData(docRef.id);

        if (user_resp) {
          SetToggleComp(true);
          setShowSpinner(false);
          setTextAreaDisable(false);
          setButtonDisable(true);
        } else {
          SetToggleComp(false);
          setShowSpinner(false);
          setButtonDisable(false);
          setTextAreaDisable(false);
        }
      } else {
        console.log("else condition");
      }
    } catch (error) {
      toast.error(error.message);
      SetToggleComp(false);
      setShowSpinner(false);
      setButtonDisable(false);
      setTextAreaDisable(false);
      // console.log("error..11..", error);
    }
  };

  async function getIP() {
    try {
      const responseip = await fetch("https://api.ipify.org/?format=json");
      const data = await responseip.json();
      console.log("data000000", data.ip);
      // return data.IPv4;
      return data.ip
    } catch (err) {
      console.log("err0000", err);
      // toast.error("Internal server error");
      setShowSpinner(false);
      SetToggleComp(true);
      return err;
    }
  }

  function getBrowserInfo() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserInfo = browser.getBrowser();
    const osInfo = browser.getOS();

    return {
      browserName: browserInfo.name,
      osName: osInfo.name,
    };
  }
  const navigateFn = () => {
    navigate("/");
  };

  useEffect(() => {
    const img = new Image();
    img.src = `${cardData.card_background_img}`;

    img.onload = () => {
      setImgLoading(false);
    };
  }, [cardData]);
  return (
    <div>
      {changeComp ? (
        imgLoading ? (
          <div
            className=" d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{ width: "4rem", height: "4rem" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div
            className="d-flex flex-column justify-content-between"
            style={{
              // backgroundImage: `linear-gradient(32deg, #${cardbgColor[1]} 0%, #${cardbgColor[0]} 100%)`,
              backgroundImage: `url(${cardData.card_background_img})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "100vh",
            }}
          >
            <div className=" d-flex flex-column ">
              <ToastContainer
                position="top-center"
                toastStyle={{ textAlign: "center" }}
              />
              <div className="">
                {loading ? (
                  ""
                ) : (
                  <span className="d-flex justify-content-center log-image mb-3 mt-3">
                    <img
                      className=" cursor-pointer"
                      src={logo}
                      onClick={navigateFn}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                )}

                <Container>
                  {toggleComp ? (
                    <Container>
                      <div className="home row ">
                        <div className=" d-flex flex-column  align-items-center ">
                          <img className="check_icon" src={sent} alt="sent" />
                          <h4 className="mt-3 text-white">Sent!</h4>
                          <button
                            onClick={hadleToggleFn}
                            className="btn_send_another_response mb-4"
                          >
                            Send another message
                          </button>
                        </div>
                      </div>
                      <div className="row  d-flex justify-content-center">
                        <div className=" footer_bg_custom d-flex flex-column parent_div">
                          <div className=" store_main_bg mt-auto mb-3 d-flex flex-column align-items-center justig-content-center py-5 px-3">
                            <div className="text_head text-center px-2">
                              Wanna Create Your{" "}
                              <br className="d-block d-sm-none" />
                              <span className="text_main"> Own Questions </span>
                              <span className="text_like_this ">
                                Like This?
                              </span>
                            </div>
                            <div className="logo_handle mt-3">
                              Then <img className="f_logo" src={logo} />
                              it
                            </div>
                            <div className="d-flex justify-between flex-column flex-sm-row mt-4 ">
                              {/* <a
                            className="me-0 me-sm-2 cursor-pointer"
                            target="_blank"
                            href="#"
                          >
                            <img
                              className="cursor-pointer responsive_image"
                              src={google}
                              alt="google"
                            />
                          </a> */}
                              <a
                                className="ms-0 ms-sm-2 mt-3 mt-sm-0 cursor-pointer"
                                target="_blank"
                                href="https://apps.apple.com/us/app/confess-anonymous-q-a/id6502970808"
                              >
                                <img
                                  src={apple}
                                  alt="apple"
                                  className="cursor-pointer responsive_image"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Container>
                  ) : (
                    <div className="row">
                      <div className="col-0 col-lg-3"></div>
                      <div className="col-12 col-lg-6 d-block d-sm-flex justify-content-center align-items-center">
                        {loading ? (
                          <div
                            className=" d-flex align-items-center justify-content-center"
                            style={{ minHeight: "100vh" }}
                          >
                            <Spinner
                              animation="border"
                              role="status"
                              style={{ width: "4rem", height: "4rem" }}
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        ) : (
                          <div className=" bubble ">
                            <div
                              className="bubble_header  justify-content-between"
                              style={{
                                backgroundColor: `#${cardData.header_colour}`,
                                color: `#${cardData.text_color}`,
                              }}
                            >
                              <div className="user-container d-flex">
                                <div className="d-flex align-items-center">
                                  <img
                                    style={{ border: "2px solid #AAAEB5" }}
                                    src={
                                      cardData.user_profile
                                      // ? cardData.user_profile
                                      // : user
                                    }
                                    alt="user"
                                    className="pfp-container"
                                  />
                                </div>
                                <div className="ms-3">
                                  <div className="usename ">
                                    {cardData.username}
                                  </div>
                                  <div className="prompt">
                                    {cardData.card_text == ""
                                      ? cardData.custom_post_text
                                      : cardData.card_text}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-column parent_div">
                              <div className="flex-one">
                                <div className="textarea-container">
                                  <textarea
                                    className={
                                      textAreaDisable ? "text_area_handle" : ""
                                    }
                                    disabled={textAreaDisable}
                                    ref={currRef}
                                    value={message}
                                    placeholder="Send me anonymous messages..."
                                    onChange={updateDataFn}
                                    // onFocus={handleTextareaFocus}
                                    // onBlur={handleTextareaBlur}
                                    maxLength="150"
                                  />
                                  <div className="d-flex justify-content-between">
                                    {
                                      <span
                                        className="error_text Warning_text"
                                        // style={{ color: "red" }}
                                      >
                                        {errorMsg}
                                      </span>
                                    }
                                    <p
                                      className=" d-flex justify-content-end me-3 Warning_text"
                                      style={{ color: "#768886" }}
                                    >
                                      Max 150 Chars
                                    </p>
                                  </div>
                                </div>

                                <button
                                  className="btn_send_response mb-4"
                                  disabled={buttonDisable}
                                  onClick={handleSendMessage}
                                >
                                  {!showSpinner ? (
                                    "Send"
                                  ) : (
                                    <Spinner animation="border" role="status">
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </Spinner>
                                  )}
                                </button>
                                {/* )} */}
                              </div>
                              <div className="store_main_bg mt-auto mb-4 d-flex flex-column align-items-center justig-content-center py-4 py-sm-5 px-3">
                                <div className="text_head text-center px-2">
                                  Wanna Create Your
                                  <br className="d-block d-sm-none" />
                                  <span className="text_main">
                                    {" "}
                                    Own Questions{" "}
                                  </span>
                                  <span className="text_like_this ">
                                    Like This?
                                  </span>
                                </div>
                                <div className="logo_handle ">
                                  Then
                                  <img className="f_logo" src={logo} />
                                  it
                                </div>
                                <div className="d-flex justify-between flex-column flex-sm-row mt-4 ">
                                  {/* <a
                                className="me-0 me-sm-2 cursor-pointer"
                                target="_blank"
                                href="#"
                              >
                                <img
                                  className="cursor-pointer responsive_image"
                                  src={google}
                                  alt="google"
                                />
                              </a> */}
                                  <a
                                    className="ms-0 ms-sm-2  cursor-pointer"
                                    target="_blank"
                                    href="https://apps.apple.com/us/app/confess-anonymous-q-a/id6502970808"
                                  >
                                    <img
                                      src={apple}
                                      alt="apple"
                                      className="cursor-pointer responsive_image"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-0 col-lg-3"></div>
                    </div>
                  )}
                </Container>
              </div>
            </div>
            <div>{loading ? "" : <Footer />}</div>
          </div>
        )
      ) : (
        <PostError />
        //     <div
        //       className="d-flex flex-column justify-content-between"
        //       style={{
        //         minHeight: "100vh",
        //       }}
        //     >
        //       <div>
        //         <span className="d-flex justify-content-center log-image mb-4 mt-3">
        //           <img
        //             className=" cursor-pointer"
        //             src={logo}
        //             onClick={navigateFn}
        //             style={{ cursor: "pointer" }}
        //           />
        //         </span>
        //       </div>
        //       <div className="d-flex flex-column justify-content-between">
        //         <div className="d-flex  justify-content-center img_handler mb-3">
        //           <img
        //             src={postImage}
        //             className="error_image"
        //             // style={{ minHeight: "20vh" }}
        //           />
        //         </div>
        //         {/* </div>

        //      <div className="col-0 col-lg-4  "></div>
        //    </siv>
        //  </div> */}

        //         <div className="d-flex flex-column justify-content-center ">
        //           <div className="d-flex  justify-content-center postError_text">
        //             Oops!{" "}
        //           </div>
        //           <div className="d-flex  justify-content-center postError_text_sub mb-4">
        //             Post not found
        //           </div>
        //           <div className="d-flex  justify-content-center postError_text_sub ">
        //             <button className=" error_button" onClick={navigateFn}>
        //               Go To Homepage
        //             </button>
        //           </div>
        //         </div>
        //       </div>
        //       <div>
        //         <Footer />
        //       </div>
        //     </div>
      )}
    </div>
  );
};

export default Home;
