import React, { useState } from "react";
import { Container } from "react-bootstrap";
// import image1 from "./images/Website/Web_View/Insta_Story_SS/WithoutShadow/Insta Stories Mockup_1 1.png";
// import image2 from "./images/Website/Web_View/Insta_Story_SS/WithoutShadow/Compressed/Insta Stories Mockup_1 2.png";
// import image3 from "./images/Website/Web_View/Insta_Story_SS/WithoutShadow/Insta Stories Mockup_1 3.png";
// import image4 from "./images/Website/Web_View/Insta_Story_SS/WithoutShadow/Compressed/Insta Stories Mockup_1 4.png";

import image1 from "./images/mini_images/Insta_story_1.jpg";
import image2 from "./images/mini_images/Insta_story_2.jpg";
import image3 from "./images/mini_images/Insta_story_3.jpg";
import image4 from "./images/mini_images/Insta_story_4.jpg";

const ComponentSecond = React.forwardRef((props, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div className="pt-4" ref={ref}>
      <div className="comp2_main">
        <Container>
          <div className="d-flex flex-column align-items-center justify-content-center px-4">
            <div className="comp_2_custom">No more waste</div>
            <div className="responsive_heading_field display-4 fw-bold">
              Pick the Sun
            </div>
            <div className="comp_2_custom_text mx-5 text-center">
              Et pulvinar nec interdum integer id urna molestie porta nullam. A,
              donec ornare sed turpis pulvinar purus maecenas quam a. Erat
              porttitor pharetra sed in
            </div>
            <div className="container images_custom_style image_size">
              <div className="row p-2 p-lg-4">
                <div className="col-6 col-lg-3  p-0  bg-transparent border_radius_Custom">
                  <img
                    src={image1}
                    alt="image1"
                    className="img-fluid w-100 image_hover_1 "
                  />
                </div>
                <div className=" col-6 col-lg-3 p-0  bg-transparent border_radius_Custom">
                  {" "}
                  <img
                    src={image2}
                    alt="image2"
                    className="img-fluid w-100 image_hover_2"
                  />
                </div>
                <div className="col-6 col-lg-3 p-0 d-none d-lg-block  bg-transparent border_radius_Custom">
                  <img
                    src={image3}
                    alt="image3"
                    className="img-fluid w-100  image_hover_3 d-none d-lg-block"
                  />
                </div>
                <div className="col-3 p-0  bg-transparent border_radius_Custom d-none d-lg-block">
                  <img
                    src={image4}
                    alt="image4"
                    className="img-fluid w-100 d-none d-lg-block image_hover_4"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
});

export default ComponentSecond;
