import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_main">
      <div className=" text-white d-flex flex-column  justify-content-center align-items-center py-3">
        <div className="mb-1 terms_div d-flex flex-column flex-sm-row text-center">
          {" "}
          <Link
            className="web_links my-sm-0 my-2"
            to="/privacy-policy"
            target="_blank"
          >
            Privacy Policy{" "}
          </Link>{" "}
          <Link
            to="/terms"
            target="_blank"
            className="ms-0 ms-sm-4 my-sm-0 my-2 web_links"
          >
            {" "}
            Terms & Conditions{" "}
          </Link>
          <Link
            to="mailto:getconfessapp@gmail.com"
            target="_blank"
            className="ms-0 ms-sm-4 my-sm-0 my-2 web_links"
          >
            {" "}
            Contact Us{" "}
          </Link>
        </div>
        <div
          className="d-flex flex-column flex-sm-row"
          style={{ fontSize: "16px" }}
        >
          <div className="text-center  mb-sm-1 mb-3"> © 2024 Confess</div>
        </div>
        <div className="font_set">
          Designed & Developed By:&nbsp;
          <Link
            className=" mb-sm-0 mb-3 web_links_comp"
            to="https://inoidsolutions.com/"
            target="_blank"
          >
            iNoid Solutions
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
